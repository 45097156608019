<template>
	<div>
		<div class="container-md">
			<customer v-model="customer" class="mb-50" @validStatusChanged="updateValidStatus"></customer>

			<div class="flex align-start">
				<div class="btn" @click.prevent="saveCustomer()" :class="{ disabled: loading || formInvalid }">Stovna</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

import Customer from '@/components/forms/Customer.vue';

export default {
	name: 'CustomerCreate',

	components: {
		Customer,
	},

	data() {
		return {
			formInvalid: true,
			loading: false,
			customer: {
				//
			},
		};
	},

	methods: {
		updateValidStatus(status) {
			this.formInvalid = status;
		},

		saveCustomer() {
			this.loading = true;

			const data = { ...this.customer };

			if (typeof data.mobileNumbers == 'object') {
				data.mobileNumbers = data.mobileNumbers.join(',');
			}

			axios
				.post('/customers', data)
				.then((response) => {
					this.$router.push({ name: 'CustomerShow', params: { id: response.data.id } });
				})
				.catch((error) => {
					console.log('error', error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
